import BlobCollectionModel from '@refinio/one.models/lib/models/BlobCollectionModel.js';
import ChannelManager from '@refinio/one.models/lib/models/ChannelManager.js';
import ConnectionsModel from '@refinio/one.models/lib/models/ConnectionsModel.js';
import DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';
import LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import JournalModel from '@refinio/one.models/lib/models/JournalModel.js';
import QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import {OEvent} from '@refinio/one.models/lib/misc/OEvent.js';
import RecipesStable from '@refinio/one.models/lib/recipes/recipes-stable.js';
import RecipesExperimental from '@refinio/one.models/lib/recipes/recipes-experimental.js';
import type {QueryOptions} from '@refinio/one.models/lib/models/ChannelManager.js';
import IoMManager from '@refinio/one.models/lib/models/IoM/IoMManager.js';
import {
    ReverseMapsStable,
    ReverseMapsForIdObjectsStable
} from '@refinio/one.models/lib/recipes/reversemaps-stable.js';
import {
    ReverseMapsExperimental,
    ReverseMapsForIdObjectsExperimental
} from '@refinio/one.models/lib/recipes/reversemaps-experimental.js';
import type {AnyObjectResult} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import Notifications from '@refinio/one.models/lib/models/Notifications.js';
import GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';
import DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import MultiUser from '@refinio/one.models/lib/models/Authenticator/MultiUser';
import BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import type {HexString} from '@refinio/one.core/lib/util/arraybuffer-to-and-from-hex-string.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import {convertIdentityToOneInstanceEndpoint} from '@refinio/one.models/lib/misc/IdentityExchange.js';

import {IFCPCcolposcopicterminologyofthecervix} from '@/resources/questionnaires/IFCPCcolposcopicterminologyofthecervix.js';
import {GeneralShortQuestionnaire} from '@/resources/questionnaires/GeneralShortQuestionnaire.js';
import {ExampleQuestionnaire} from '@/resources/questionnaires/ExampleQuestionnaire.js';
import {WhpQuestionnaire} from '@/resources/questionnaires/WhpQuestionnaire.js';
import {
    documentType,
    personImageType,
    personStatusType,
    questionnaireTypeDisplay,
    diaryType,
    wbcDiffType,
    bodyTemperatureType
} from '@/hooks/journal/hooks.js';
import glueOneGroupImageBase64 from '@/resources/base64Images/glueOneGroup.js';
import {base64ToArrayBuffer} from '@/utils/Utils.js';
import {SchmerzerfassungQuestionnaireVerbalAssessmentDE} from '@/resources/questionnaires/SchmerzerfassungQuestionnaire_verbalAssesment.js';
import {SchmerzerfassungQuestionnaireFaceAssessmentDE} from '@/resources/questionnaires/SchmerzerfassungQuestionnaire_faceAssesment.js';
import {SchmerzerfassungQuestionnaireNumAssessmentDE} from '@/resources/questionnaires/SchmerzerfassungQuestionnaire_numAssesment.js';
import {CervicalCancerScreeningAndTreatmentRegister} from '@/resources/questionnaires/CervicalCancerScreeningAndTreatmentRegister.js';
import {ColposcopyExaminationRecord} from '@/resources/questionnaires/ColposcopyExaminationRecord.js';
import {Onboarding} from '@/resources/questionnaires/Onboarding.js';
import {EcpireArmpitLeftQuestionnaire} from '@/root/ecpire/resources/questionnaires/EcpireArmpitLeftQuestionnaire.js';
import {EcpireArmpitRightQuestionnaire} from '@/root/ecpire/resources/questionnaires/EcpireArmpitRightQuestionnaire.js';
import {EcpireBreastLeftQuestionnaire} from '@/root/ecpire/resources/questionnaires/EcpireBreastLeftQuestionnaire.js';
import {EcpireBreastRightQuestionnaire} from '@/root/ecpire/resources/questionnaires/EcpireBreastRightQuestionnaire.js';
import {QuestionnaireEQ5D3L} from '@/resources/questionnaires/QuestionnaireEQ5D3L.js';

import {FlexibelRegistrierung} from '@/flexibel/resources/questionnaires/FlexibelRegistrierung_de.js';
import {PainAssesmentRegistrierung} from '@/flexibel/resources/questionnaires/PainAssesmentRegistrierung_de.js';
import {DorAdoFACTB} from '@/flexibel/resources/questionnaires/DorAdoFACTB.js';
import {DorAdoDistressThermometer} from '@/flexibel/resources/questionnaires/DorAdoDistressThermometer.js';
import {DorAdoMAUQ} from '@/flexibel/resources/questionnaires/DorAdoMAUQ.js';
import {DorAdoRegistrierung} from '@/flexibel/resources/questionnaires/DorAdoRegistrierung.js';
import {DorAdoSonstige} from '@/flexibel/resources/questionnaires/DorAdoSonstige.js';
import {DorAdoGelenkschmerzen} from '@/flexibel/resources/questionnaires/DorAdoGelenkschmerzen.js';
import {DorAdoMudigkeit} from '@/flexibel/resources/questionnaires/DorAdoMudigkeit.js';
import {DorAdoHitzewallungen} from '@/flexibel/resources/questionnaires/DorAdoHitzewallungen.js';
import {DorAdoUbelkeit} from '@/flexibel/resources/questionnaires/DorAdoUbelkeit.js';
import {DorAdoDurchfall} from '@/flexibel/resources/questionnaires/DorAdoDurchfall.js';
import {DorAdoErbrechen} from '@/flexibel/resources/questionnaires/DorAdoErbrechen.js';
import {DorAdoMARSD} from '@/flexibel/resources/questionnaires/DorAdoMARSD.js';

import {
    SignupCertificateRecipe,
    SignupCertificateReverseMap
} from '@/root/malawi_demo/SignupCertificate.js';
import {getGroupHash} from '@/config.js';
import {ResClueQuestionnaire} from '@/resources/questionnaires/ResClueQuestionnaire.js';
import {initLeuteLog, shutdownLeuteLog} from '@/model/LeuteLog.js';
import LeuteAccessRightsManager from '@/model/LeuteAccessRightsManager.js';
import BlacklistModel from '@/model/BlacklistModel.js';
import {GeneralFeedback} from '@/edda/resources/questionnaires/GeneralFeedback.js';
import {SpecificFeedback} from '@/edda/resources/questionnaires/SpecificFeedback.js';
import {addIopPeerToLeute, type PhysicianIopPeerInfo} from '@/flexibel/config.js';
import ClinicModel from '@/flexibel/model/roles/ClinicModel.js';
import PhysicianModel from '@/flexibel/model/roles/PhysicianModel.js';
import AdminModel from '@/flexibel/model/roles/AdminModel.js';
import PatientModel from './roles/PatientModel.js';
import EddaFlexibelChatModel from './EddaFlexibelChatModel.js';
import SharingModel from './SharingModel.js';

export default class Model {
    public onOneModelsReady = new OEvent<() => void>();
    public readonly physicianIopPeers?: PhysicianIopPeerInfo[];

    constructor(
        commServerUrl: string,
        physicianIopPeers: PhysicianIopPeerInfo[] | undefined,
        rootOfTrustPublicSignKey: HexString,
        clinicOrganisationName: string
    ) {
        this.physicianIopPeers = physicianIopPeers;

        // Setup basic models
        this.leuteModel = new LeuteModel(commServerUrl, true);
        this.channelManager = new ChannelManager(this.leuteModel);
        this.questionnaireModel = new QuestionnaireModel(this.channelManager);
        this.documentModel = new DocumentModel(this.channelManager);
        this.iom = new IoMManager(this.leuteModel, commServerUrl);
        this.wbcDiffModel = new WbcDiffModel(this.channelManager);
        this.diaryModel = new DiaryModel(this.channelManager);
        this.bodyTemperatureModel = new BodyTemperatureModel(this.channelManager);
        this.journalModel = new JournalModel([
            {
                event: this.leuteModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.leuteModel.retrieveStatusesForJournal(queryOptions),
                eventType: personStatusType
            },
            {
                event: this.leuteModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.leuteModel.retrievePersonImagesForJournal(queryOptions),
                eventType: personImageType
            },
            {
                event: this.questionnaireModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.questionnaireModel.responsesIterator(queryOptions),
                eventType: questionnaireTypeDisplay
            },
            {
                event: this.documentModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.documentModel.documentsIterator(queryOptions),
                eventType: documentType
            },
            {
                event: this.bodyTemperatureModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.bodyTemperatureModel.bodyTemperaturesIterator(queryOptions),
                eventType: bodyTemperatureType
            },
            {
                event: this.diaryModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.diaryModel.entriesIterator(queryOptions),
                eventType: diaryType
            },
            {
                event: this.wbcDiffModel.onUpdated,
                retrieveFn: (queryOptions?: QueryOptions) =>
                    this.wbcDiffModel.observationsIterator(queryOptions),
                eventType: wbcDiffType
            }
        ]);
        this.connections = new ConnectionsModel(this.leuteModel, {
            commServerUrl,
            acceptIncomingConnections: true,
            acceptUnknownInstances: true,
            acceptUnknownPersons: false,
            allowPairing: true,
            allowDebugRequests: true,
            pairingTokenExpirationDuration: 60000 * 15, // qr-code (invitation) timeout
            establishOutgoingConnections: true
        });
        this.topicModel = new TopicModel(this.channelManager, this.leuteModel);
        this.one = new MultiUser({
            recipes: [...RecipesStable, ...RecipesExperimental, SignupCertificateRecipe],
            reverseMaps: new Map([
                ...ReverseMapsStable,
                ...ReverseMapsExperimental,
                SignupCertificateReverseMap
            ]),
            reverseMapsForIdObjects: new Map([
                ...ReverseMapsForIdObjectsStable,
                ...ReverseMapsForIdObjectsExperimental
            ]),
            storageInitTimeout: 20000
        });
        this.LeuteAccessRightsManager = new LeuteAccessRightsManager(
            this.channelManager,
            this.connections,
            this.leuteModel
        );
        this.blobCollectionModel = new BlobCollectionModel(this.channelManager);
        this.notifications = new Notifications(this.channelManager);
        this.blacklistModel = new BlacklistModel();
        this.adminModel = new AdminModel(this.leuteModel, rootOfTrustPublicSignKey);
        this.clinicModel = new ClinicModel(
            this.adminModel,
            this.leuteModel,
            clinicOrganisationName
        );
        this.physicianModel = new PhysicianModel(this.leuteModel, this.clinicModel);
        this.patientModel = new PatientModel(this.leuteModel);
        this.eddaFlexibelChatModel = new EddaFlexibelChatModel(
            this.leuteModel,
            this.topicModel,
            this.physicianModel,
            this.patientModel
        );
        this.sharingModel = new SharingModel(
            this.leuteModel,
            this.patientModel,
            this.physicianModel
        );

        // ######## Event handler of models ########

        // Setup event handler that initialize the models when somebody logged in
        // and shuts down the model when somebody logs out.
        this.one.onLogin(this.init.bind(this));
        this.one.onLogout(this.shutdown.bind(this));

        this.leuteModel.afterMainIdSwitch((oldIdentity, newIdentity) => {
            this.leuteModel.trust
                .certify(
                    'RightToDeclareTrustedKeysForEverybodyCertificate',
                    {
                        beneficiary: oldIdentity
                    },
                    newIdentity
                )
                .catch(console.error);
        });
    }

    /**
     * Initialize all the models.
     */
    public async init(_instanceName: string, _secret: string): Promise<void> {
        try {
            objectEvents.determinePriorityOverride = (result: AnyObjectResult) => {
                if (result.obj.$type$ === 'Person') {
                    return 11;
                }
                if (result.obj.$type$ === 'Profile') {
                    return 10;
                }

                return 0;
            };

            await objectEvents.init();
            initLeuteLog(this.channelManager);

            // Initialize contact model. This is the base for identity handling and everything
            await this.leuteModel.init();

            const binGroup = await this.leuteModel.createGroup('bin');
            const everyoneGroup = await GroupModel.constructFromLatestProfileVersionByGroupName(
                LeuteModel.EVERYONE_GROUP_NAME
            );
            this.blacklistModel.init(binGroup, everyoneGroup);

            // Give the main identity the ability to define trusted keys
            const myMainId = await this.leuteModel.myMainIdentity();
            await this.leuteModel.trust.certify(
                'RightToDeclareTrustedKeysForEverybodyCertificate',
                {
                    beneficiary: myMainId
                }
            );

            await this.iom.init();

            // Construct groups
            // This will create a one.core group for the leute and the glue replicants (Group names
            // leuteReplicant/glueReplicant) so that it is easier to share stuff just with those
            // replicants, because those groups have always the same name.

            const groups = {
                leuteReplicant: await getGroupHash('leuteReplicant'),
                glueReplicant: await getGroupHash('glueReplicant'),
                everyone: everyoneGroup.groupIdHash,
                iom: (await this.iom.iomGroup()).groupIdHash
            };

            await this.channelManager.init();
            await this.blobCollectionModel.init();
            await this.topicModel.init();
            await this.questionnaireModel.init();
            await this.documentModel.init();
            await this.LeuteAccessRightsManager.init(groups);
            await this.connections.init(this.blacklistModel.blacklistGroupModel);
            await this.journalModel.init();
            await this.wbcDiffModel.init();
            await this.diaryModel.init();
            await this.bodyTemperatureModel.init();

            const peerIds: Array<SHA256IdHash<Person>> = [];
            if (this.physicianIopPeers) {
                for (const peer of this.physicianIopPeers) {
                    peerIds.push(
                        (await convertIdentityToOneInstanceEndpoint(peer.identity)).obj.personId
                    );
                }
            }

            // edda-flexibel models, order is important
            await this.sharingModel.init({
                peerIds,
                onPhysician: async () => {
                    if (this.physicianIopPeers) {
                        for (const peer of this.physicianIopPeers) {
                            await addIopPeerToLeute(this.leuteModel, peer);
                        }
                    }
                }
            });
            this.eddaFlexibelChatModel.init();
            await this.physicianModel.init();
            this.clinicModel.init();
            await this.adminModel.init();
            await this.patientModel.init();

            // Setup application specific stuff
            this.questionnaireModel.registerQuestionnaires([
                ExampleQuestionnaire,
                GeneralShortQuestionnaire,
                WhpQuestionnaire,
                SchmerzerfassungQuestionnaireVerbalAssessmentDE,
                SchmerzerfassungQuestionnaireFaceAssessmentDE,
                SchmerzerfassungQuestionnaireNumAssessmentDE,
                EcpireArmpitLeftQuestionnaire,
                EcpireArmpitRightQuestionnaire,
                EcpireBreastLeftQuestionnaire,
                EcpireBreastRightQuestionnaire,
                Onboarding,
                CervicalCancerScreeningAndTreatmentRegister,
                IFCPCcolposcopicterminologyofthecervix,
                ResClueQuestionnaire,
                ColposcopyExaminationRecord,
                QuestionnaireEQ5D3L,
                GeneralFeedback,
                SpecificFeedback,
                FlexibelRegistrierung,
                PainAssesmentRegistrierung,
                DorAdoRegistrierung,
                DorAdoSonstige,
                DorAdoGelenkschmerzen,
                DorAdoMudigkeit,
                DorAdoHitzewallungen,
                DorAdoFACTB,
                DorAdoDistressThermometer,
                DorAdoMAUQ,
                DorAdoUbelkeit,
                DorAdoDurchfall,
                DorAdoErbrechen,
                DorAdoMARSD
            ]);
            const everyoneTopic = await this.topicModel.createEveryoneTopic();
            const glueTopic = await this.topicModel.createGlueTopic();

            // Create a glue.one group as dummy for the ui. Nobody is in there
            const glueGroup = await this.leuteModel.createGroup('glue.one');
            if (glueGroup.picture === undefined) {
                glueGroup.picture = base64ToArrayBuffer(glueOneGroupImageBase64);
                await glueGroup.saveAndLoad();
            }
            this.channelManager.setChannelSettingsMaxSize(everyoneTopic.channel, 1024 * 1024 * 100);
            this.channelManager.setChannelSettingsMaxSize(glueTopic.channel, 1024 * 1024 * 100);
            this.channelManager.setChannelSettingsAppendSenderProfile(glueTopic.channel, true);

            this.onOneModelsReady.emit();
        } catch (e) {
            console.log('Models init failed', e);
            // Shutdown all models when initialization failed.
            // Shutdown should not throw, even if models were not initialized.
            // So this call should never throw. If it throws we should return the
            // original error, not the one from shutdown, because otherwise the original
            // problem will be obfuscated. => console.error is ok here. Perhaps later we
            // should emit it as error event when we have a proper setup how to handle those.
            await this.shutdown().catch(console.error);
            throw e;
        }
    }

    /**
     * Shutdown the models.
     */
    public async shutdown(): Promise<void> {
        try {
            await this.eddaFlexibelChatModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.patientModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.adminModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.clinicModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.physicianModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.bodyTemperatureModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.wbcDiffModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.diaryModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.blobCollectionModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.connections.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.questionnaireModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.documentModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.channelManager.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.iom.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.leuteModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.blacklistModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.journalModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        shutdownLeuteLog();
        await objectEvents.shutdown();
    }

    public blacklistModel: BlacklistModel;
    public channelManager: ChannelManager;
    public documentModel: DocumentModel;
    public leuteModel: LeuteModel;
    public one: MultiUser;
    public connections: ConnectionsModel;
    public LeuteAccessRightsManager: LeuteAccessRightsManager;
    public blobCollectionModel: BlobCollectionModel;
    public journalModel: JournalModel;
    public topicModel: TopicModel;
    public iom: IoMManager;
    public questionnaireModel: QuestionnaireModel;
    public notifications: Notifications;
    public diaryModel: DiaryModel;
    public wbcDiffModel: WbcDiffModel;
    public bodyTemperatureModel: BodyTemperatureModel;
    public adminModel: AdminModel;
    public clinicModel: ClinicModel;
    public physicianModel: PhysicianModel;
    public patientModel: PatientModel;
    public eddaFlexibelChatModel: EddaFlexibelChatModel;
    private sharingModel: SharingModel;
}
