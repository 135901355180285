import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type {SHA256Hash} from '@refinio/one.core/lib/util/type-checks.js';
import {type SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {OneObjectTypes, Person} from '@refinio/one.core/lib/recipes.js';
import type {RelationCertificate} from '@refinio/one.models/lib/recipes/Certificates/RelationCertificate.js';
import {Model} from '@refinio/one.models/lib/models/Model.js';
import {OEvent} from '@refinio/one.models/lib/misc/OEvent.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import {createAccess} from '@refinio/one.core/lib/access.js';
import {SET_ACCESS_MODE} from '@refinio/one.core/lib/storage-base-common.js';
import type {Signature} from '@refinio/one.models/lib/recipes/SignatureRecipes.js';
import {getAllEntries} from '@refinio/one.core/lib/reverse-map-query.js';
import type {UnversionedObjectResult} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import {getObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {OneInstanceEndpoint} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import {createMessageBus} from '@refinio/one.core/lib/message-bus.js';

import type ClinicModel from './ClinicModel.js';
import {getGroup, getPersonIdsForRole} from './utils.js';

const MessageBus = createMessageBus('ClinicModels');

export default class PhysicianModel extends Model {
    static readonly PHYSICIANS_GROUP_NAME = 'flexibelPhysicians';

    private leuteModel: LeuteModel;
    private clinicModel: ClinicModel;
    private onNewPhysicianDisconnectListeners: Array<() => void>;
    private onClinicDisconnectListener: (() => void) | undefined;

    public onPhysiciansChange = new OEvent<() => void | Promise<void>>();

    constructor(leuteModel: LeuteModel, clinicModel: ClinicModel) {
        super();
        this.leuteModel = leuteModel;
        this.clinicModel = clinicModel;
        this.onNewPhysicianDisconnectListeners = [];
    }

    public async init(): Promise<void> {
        // without a clinic we can not verify physicians
        this.onClinicDisconnectListener = this.clinicModel.onClinicsChange(async () => {
            MessageBus.send('debug', 'PhysicianModel - onClinicsChange listener - start');

            // sync physicians group
            await this.addToPhysiciansGroup(
                await getPersonIdsForRole(this.leuteModel, this.isPhysician.bind(this))
            );

            // listen for new physicians to add to group
            this.processNewPhysicians();
        });

        // sync physicians group
        const physicians = await getPersonIdsForRole(this.leuteModel, this.isPhysician.bind(this));

        if (physicians.length > 0) {
            await this.addToPhysiciansGroup(physicians, true);
        }
    }

    // required by super
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        if (this.onClinicDisconnectListener !== undefined) {
            this.onClinicDisconnectListener();
        }
        this.onClinicDisconnectListener = undefined;

        for (const onNewPhysicianDisconnectListener of this.onNewPhysicianDisconnectListeners) {
            onNewPhysicianDisconnectListener();
        }
        this.onNewPhysicianDisconnectListeners = [];
    }

    public async isPhysician(personId?: SHA256IdHash<Person>): Promise<boolean> {
        return (await this.getValidPhysicianCertificateData(personId)) !== undefined;
    }

    public async isPhysicianRelationCertificateData(
        obj: OneObjectTypes | undefined,
        owner?: SHA256IdHash<Person>
    ): Promise<boolean> {
        if (
            !obj ||
            obj.$type$ !== 'RelationCertificate' ||
            obj.relation !== 'is Physician at' ||
            obj.app !== 'edda.flexibel.one'
        ) {
            return false;
        }

        const ownerPersonId = owner ?? (await this.leuteModel.myMainIdentity());

        return obj.person1 === ownerPersonId;
    }

    public async getPhysiciansGroup(): Promise<GroupModel> {
        return getGroup(PhysicianModel.PHYSICIANS_GROUP_NAME);
    }

    public async getValidPhysicianCertificateData(personId?: SHA256IdHash<Person>): Promise<
        | {
              certificate: RelationCertificate & {hash: SHA256Hash<RelationCertificate>};
              signature: Signature & {hash: SHA256Hash<Signature>};
          }
        | undefined
    > {
        const targetPersonId = personId ?? (await this.leuteModel.myMainIdentity());
        const certificateHashes = await getAllEntries(targetPersonId, 'RelationCertificate');

        for (const certificateHash of certificateHashes) {
            const certificate = await getObject(certificateHash);
            const signatureHashes = await getAllEntries(certificateHash, 'Signature');

            for (const signatureHash of signatureHashes) {
                const signature = await getObject(signatureHash);
                const trusted =
                    (await this.leuteModel.trust.findKeyThatVerifiesSignature(signature)) !==
                    undefined;

                if (
                    trusted &&
                    (await this.isPhysicianRelationCertificateData(certificate, targetPersonId))
                ) {
                    return {
                        certificate: {...certificate, hash: certificateHash},
                        signature: {...signature, hash: signatureHash}
                    };
                }
            }
        }

        return undefined;
    }

    /** ***** Private ***** **/

    private async addToPhysiciansGroup(
        addPhysicians: Array<SHA256IdHash<Person>>,
        emitChangeNonEmptyGroup: boolean = false
    ): Promise<boolean> {
        const physiciansGroup = await this.getPhysiciansGroup();
        const me = await this.leuteModel.me();
        const myIndentitis = me.identities();
        let addedNew = false;
        let addedNewTrust = false;

        for (const physician of addPhysicians) {
            const someone = await this.leuteModel.getSomeone(physician);

            if (someone === undefined) {
                // usually should not happen
                throw Error(`Can not find someone with person id ${physician}`);
            }

            for (const profile of await someone.profiles()) {
                if (profile.loadedVersion === undefined) {
                    // usually should not happen
                    throw Error(
                        `Profile of person id ${physician} profile id ${profile.idHash} has no loaded version`
                    );
                }

                if (physiciansGroup.persons.includes(physician)) {
                    continue;
                }

                const relationCertificateData =
                    await this.getValidPhysicianCertificateData(physician);

                if (relationCertificateData === undefined) {
                    continue;
                }

                physiciansGroup.persons.push(physician);
                addedNew = true;

                MessageBus.send('debug', 'PhysicianModel - addToPhysiciansGroup', physician);

                if (!myIndentitis.includes(physician)) {
                    await createAccess([
                        {
                            id: physiciansGroup.groupIdHash,
                            person: [physician],
                            group: [],
                            mode: SET_ACCESS_MODE.ADD
                        }
                    ]);

                    addedNewTrust = true;
                    await this.leuteModel.trust.certify('TrustKeysCertificate', {
                        profile: profile.loadedVersion
                    });
                }

                // share member cert and profile with group

                await createAccess([
                    {
                        id: profile.idHash,
                        person: [],
                        group: [physiciansGroup.groupIdHash],
                        mode: SET_ACCESS_MODE.ADD
                    }
                ]);

                await createAccess([
                    {
                        object: relationCertificateData.certificate.hash,
                        person: [],
                        group: [physiciansGroup.groupIdHash],
                        mode: SET_ACCESS_MODE.ADD
                    }
                ]);
                await createAccess([
                    {
                        object: relationCertificateData.signature.hash,
                        person: [],
                        group: [physiciansGroup.groupIdHash],
                        mode: SET_ACCESS_MODE.ADD
                    }
                ]);
            }
        }

        if (addedNewTrust) {
            await this.leuteModel.trust.refreshCaches(); // Just a hack until we have a better way of refresh
        }

        if (addedNew) {
            await physiciansGroup.saveAndLoad();
        }

        if ((emitChangeNonEmptyGroup && physiciansGroup.persons.length > 0) || addedNew) {
            MessageBus.send('debug', 'PhysicianModel - addToPhysiciansGroup - emit');
            this.onPhysiciansChange.emit();
        }

        return addedNew;
    }

    private processNewPhysicians(): void {
        if (this.onNewPhysicianDisconnectListeners.length > 0) {
            return;
        }
        MessageBus.send('debug', 'PhysicianModel - processNewPhysicians - init');
        this.onNewPhysicianDisconnectListeners.push(
            objectEvents.onUnversionedObject(
                this.checkForPhysicianSignature.bind(this),
                'PhysicianModel: wait for physician',
                'Signature'
            )
        );

        this.onNewPhysicianDisconnectListeners.push(
            this.leuteModel.onNewOneInstanceEndpoint(async (endpoint: OneInstanceEndpoint) => {
                // if not physician that is either
                // the person is not a physician or
                // we have not received his certificate
                // see other onNewPhysicianDisconnectListeners
                // that handle those cases
                if (!(await this.isPhysician(endpoint.personId))) {
                    MessageBus.send(
                        'debug',
                        'PhysicianModel - processNewPhysicians - new instance - not physician (yet?)',
                        endpoint
                    );
                    return;
                }

                // add new physicians to group
                await this.addToPhysiciansGroup([endpoint.personId]);
            })
        );
    }

    private async checkForPhysicianSignature(
        signatureResult: UnversionedObjectResult<Signature>
    ): Promise<void> {
        const relationCertificate = await getObject(signatureResult.obj.data);

        if (relationCertificate.$type$ !== 'RelationCertificate') {
            return;
        }

        if (
            !(await this.isPhysicianRelationCertificateData(
                relationCertificate,
                relationCertificate.person1
            ))
        ) {
            return;
        }

        try {
            await this.leuteModel.getSomeone(relationCertificate.person1);
        } catch (_e) {
            // catches cases where we have not received clinic profile
            // see other onNewPhysicianDisconnectListeners
            // that handle the new profile case
            MessageBus.send(
                'debug',
                'PhysicianModel - checkForPhysicianSignature - no physician someone (yet?)',
                relationCertificate
            );
            return;
        }

        // get both signature and certificate data
        const certData = await this.getValidPhysicianCertificateData(relationCertificate.person1);

        if (certData === undefined) {
            MessageBus.send(
                'debug',
                'PhysicianModel - processNewPhysicians - signature - not valid physician cert',
                relationCertificate,
                certData
            );
            // (signature contains certificate) no certificate means no valid signature
            return;
        }

        if (!(await this.clinicModel.isClinic(certData.signature.issuer))) {
            MessageBus.send(
                'debug',
                'PhysicianModel - processNewPhysicians - signature - issuer not clinic (yet?)',
                certData
            );
            // could not find valid clinic issuer
            // Either no clinic &/|| admin profile present yet (see init for processing this case)
            // or the issuer is not valid
            return;
        }

        // add new physicians to group
        await this.addToPhysiciansGroup([relationCertificate.person1]);
    }
}
