import type {Person} from '@refinio/one.core/lib/recipes.js';
import {type SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import {Model} from '@refinio/one.models/lib/models/Model.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';

import type PatientModel from './roles/PatientModel.js';
import type PhysicianModel from './roles/PhysicianModel.js';
import {getGroup} from './roles/utils.js';

interface ChatOptions {
    onlyForPatientIds?: Array<SHA256IdHash<Person>>;
}

export default class EddaFlexibelChatModel extends Model {
    public static readonly NAME_FORMAT = {
        start: 'eddaFlexibelChat',
        separator: '_',
        addition: '{patientPersonName}'
    } as const;

    private leuteModel: LeuteModel;
    private topicModel: TopicModel;
    private physicianModel: PhysicianModel;
    private patientModel: PatientModel;
    private onNewPatientDisconnectListener: (() => void) | undefined;
    private onNewPhysiciansDisconnectListener: (() => void) | undefined;

    constructor(
        leuteModel: LeuteModel,
        topicModel: TopicModel,
        physicianModel: PhysicianModel,
        patientModel: PatientModel
    ) {
        super();
        this.physicianModel = physicianModel;
        this.patientModel = patientModel;
        this.topicModel = topicModel;
        this.leuteModel = leuteModel;
    }

    public init(options?: ChatOptions): void {
        this.onNewPatientDisconnectListener = this.patientModel.onPatientChange(
            this.refresh.bind(this, options)
        );

        this.onNewPhysiciansDisconnectListener = this.physicianModel.onPhysiciansChange(
            this.refresh.bind(this, options)
        );
    }

    // required by super
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        if (this.onNewPatientDisconnectListener !== undefined) {
            this.onNewPatientDisconnectListener();
        }
        this.onNewPatientDisconnectListener = undefined;

        if (this.onNewPhysiciansDisconnectListener !== undefined) {
            this.onNewPhysiciansDisconnectListener();
        }
        this.onNewPhysiciansDisconnectListener = undefined;
    }

    public getChatName(patientPersonId: SHA256IdHash<Person>): string {
        const start = EddaFlexibelChatModel.NAME_FORMAT.start;
        const separator = EddaFlexibelChatModel.NAME_FORMAT.separator;
        const addition = EddaFlexibelChatModel.NAME_FORMAT.addition.replaceAll(
            '{patientPersonName}',
            this.leuteModel.getPersonName(patientPersonId)
        );
        return `${start}${separator}${addition}`;
    }

    public async chatExists(patientPersonId: SHA256IdHash<Person>): Promise<boolean> {
        return (
            (await this.topicModel.topics.queryByName(this.getChatName(patientPersonId))).length > 0
        );
    }

    /** ***** Private ***** **/

    private async getPatients(options?: ChatOptions): Promise<SHA256IdHash<Person>[]> {
        const patientsGroup = await this.patientModel.getPatientsGroup();

        return options !== undefined && options.onlyForPatientIds !== undefined
            ? patientsGroup.persons.filter(
                  pId =>
                      options !== undefined &&
                      options.onlyForPatientIds !== undefined &&
                      options.onlyForPatientIds.includes(pId)
              )
            : patientsGroup.persons;
    }

    private async refresh(options?: ChatOptions): Promise<void> {
        const patients = await this.getPatients(options);
        const physiciansGroup = await this.physicianModel.getPhysiciansGroup();

        for (const patientPersonId of patients) {
            await this.sync(patientPersonId, physiciansGroup.persons);
        }
    }

    private async sync(
        patientPersonId: SHA256IdHash<Person>,
        physicians?: Array<SHA256IdHash<Person>>
    ): Promise<void> {
        const physiciansGroup = await this.physicianModel.getPhysiciansGroup();
        const physiciansIds = physicians ?? physiciansGroup.persons;

        const name = this.getChatName(patientPersonId);
        const chatGroup = await getGroup(name);
        let newParticipants = false;

        for (const physiciansId of physiciansIds) {
            if (!chatGroup.persons.includes(physiciansId)) {
                chatGroup.persons.push(physiciansId);
                newParticipants = true;
            }
        }

        if (!chatGroup.persons.includes(patientPersonId)) {
            chatGroup.persons.push(patientPersonId);
            newParticipants = true;
        }

        if (newParticipants) {
            await chatGroup.saveAndLoad();
        }

        if (newParticipants) {
            await this.topicModel.addGroupToTopic(
                chatGroup.groupIdHash,
                await this.topicModel.createGroupTopic(name, name, patientPersonId)
            );
        }

        await this.leuteModel.createGroup(name);

        if (newParticipants) {
            this.onUpdated.emit();
        }
    }
}
