import React, {useState} from 'react';
import type {ReactElement} from 'react';
import {BrowserRouter, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {APP_BAR_MODE} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import CreateInvitation from '@refinio/one.ui/lib/ui/views/invitation/CreateInvitation.js';

import TrustView from '@/components/trust/TrustView.js';
import AppBarSettings from '@/components/appBar/AppBarSettings.js';
import PageNotFound from '@/components/PageNotFound.js';
import {useAuthenticationState} from '@/hooks/authenticator/hooks.js';
import {useIndexedDBNotificationOrError} from '@/hooks/indexedDB/errorHandling.js';
import EventCalendar from '@/root/calendar/EventCalendar.js';
import ChatRouter from '@/root/chat/ChatRouter.js';
import {getAdditionalAttachmentViews} from '@/root/chat/attachmentViews/attachmentViewRenderers.js';
import ConnectionsView from '@/root/connections/ConnectionsView.js';
import QrScanner from '@/components/qrScanner/QrScanner.js';
import LeuteView from '@/root/contacts/LeuteView.js';
import DebugRouter from '@/root/debug/DebugRouter.js';
import DocumentRouter from '@/root/document/DocumentRouter.js';
import Downloads from '@/root/downloads/Downloads.js';
import Ecpire from '@/root/ecpire/Ecpire.js';
import GroupRouter from '@/root/group/GroupRouter.js';
import IdentityRouter from '@/root/identity/IdentityRouter.js';
import IoMRouter from '@/root/iom/IoMRouter.js';
import JournalRouter from '@/root/journal/JournalRouter.js';
import PatientsRouter from '@/root/malawi_demo/patients/PatientsRouter.js';
import {QuestionnaireRouter} from '@/root/questionnaires/QuestionnaireRouter.js';
import SettingsRouter from '@/root/settings/SettingsRouter.js';
import SomeoneRouter from '@/root/someone/SomeoneRouter.js';
import SignKeysRouter from '@/root/sign_keys/SignKeysRouter.js';
import WBCRouter from '@/root/wbc/WBCRouter.js';
import Home from '@/root/home/Home.js';
import DiaryRouter from '@/root/diary/DiaryRouter.js';
import BodyTemperatureRouter from '@/root/bodyTemp/BodyTemperatureRouter.js';
import ChatMessageDetailsRouter from '@/root/chat/ChatMessageDetailsRouter.js';

import AboutRouter from '@/edda/root/about/AboutRouter.js';

import type Model from '@/flexibel/model/Model.js';
import {RootRoute} from '@/flexibel/root/RootRoute.js';
import PageTemplate from '@/flexibel/components/PageTemplate.js';
import ProfileRouter from '@/flexibel/root/profile/ProfileRouter.js';
import {
    getFlexibelAdditionalIndicators,
    getFlexibelCertificateSettings
} from '@/flexibel/components/trust/common/utils.js';

import '@/Ui.css';
import '@/Primary.css';
import '@/flexibel/Ui.css';
import '@/flexibel/Primary.css';
import startGroupChat from './contacts/StartGroupChat';

/**
 * Leute specific Router
 * @param props
 * @constructor
 */
export function AppRouter(props: {model: Model; app: string}): ReactElement {
    useIndexedDBNotificationOrError();
    const i18n = useTranslation();
    const {
        one,
        journalModel,
        leuteModel,
        connections,
        topicModel,
        channelManager,
        iom,
        questionnaireModel,
        notifications,
        documentModel,
        blacklistModel,
        wbcDiffModel,
        diaryModel,
        bodyTemperatureModel,
        adminModel,
        patientModel,
        clinicModel,
        physicianModel,
        eddaFlexibelChatModel
    } = props.model;
    const authenticationState = useAuthenticationState(one);
    const [onboardingProcess, setOnboardingProcess] = useState<boolean>(
        authenticationState === 'logged_in'
    );

    function getTrustView(
        hashes: SHA256Hash | SHA256IdHash | (SHA256Hash | SHA256IdHash)[] | undefined
    ): ReactElement {
        return (
            <TrustView
                leuteModel={leuteModel}
                hashes={hashes}
                getOverwriteCertificateSettings={getFlexibelCertificateSettings(
                    leuteModel,
                    adminModel,
                    clinicModel,
                    physicianModel
                )}
                getAdditionalIndicators={getFlexibelAdditionalIndicators(adminModel)}
            />
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                {!onboardingProcess ? (
                    <Route
                        path="*"
                        element={
                            <PageTemplate className={props.app}>
                                <RootRoute
                                    questionnaireModel={questionnaireModel}
                                    leuteModel={leuteModel}
                                    one={one}
                                    onDone={() => setOnboardingProcess(true)}
                                    app={props.app}
                                />
                            </PageTemplate>
                        }
                    />
                ) : (
                    <>
                        <Route
                            element={
                                <PageTemplate className={props.app}>
                                    <Outlet />
                                </PageTemplate>
                            }
                        >
                            <Route index element={<Navigate to="/home" />} />
                            <Route path="register/*" element={<Navigate to="/home" />} />
                            <Route path="login/*" element={<Navigate to="/home" />} />
                            <Route
                                path="home"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.home')}
                                        />
                                        <Home
                                            leuteModel={leuteModel}
                                            documentModel={documentModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="ecpire"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('ecpire.title')}
                                        />
                                        <Ecpire
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                            topicModel={topicModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="calendar"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('calendar.title')}
                                        />
                                        <EventCalendar
                                            journalModel={journalModel}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="settings/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            title={i18n.t('menu.settings')}
                                            mode={APP_BAR_MODE.BROWSE}
                                        />
                                        <SettingsRouter
                                            leuteModel={leuteModel}
                                            one={one}
                                            connections={connections}
                                            channelManager={channelManager}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="chat/*"
                                element={
                                    <ChatRouter
                                        topicModel={topicModel}
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                        notifications={notifications}
                                        attachmentViewAdditions={getAdditionalAttachmentViews(
                                            questionnaireModel,
                                            bodyTemperatureModel,
                                            wbcDiffModel,
                                            diaryModel
                                        )}
                                    />
                                }
                            />
                            <Route
                                path="message/*"
                                element={
                                    <ChatMessageDetailsRouter
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="profile/*"
                                element={
                                    <ProfileRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={getTrustView}
                                    />
                                }
                            />
                            <Route
                                path="someone/*"
                                element={
                                    <SomeoneRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={getTrustView}
                                    />
                                }
                            />
                            <Route
                                path="group/*"
                                element={
                                    <GroupRouter
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        blacklistModel={blacklistModel}
                                    />
                                }
                            />
                            <Route
                                path="iom/*"
                                element={
                                    <IoMRouter
                                        leuteModel={leuteModel}
                                        iomRequestManager={iom.requestManager}
                                    />
                                }
                            />
                            <Route
                                path="debug/*"
                                element={
                                    <DebugRouter
                                        leuteModel={leuteModel}
                                        connectionsModel={connections}
                                        channelManager={channelManager}
                                        iomManager={iom}
                                    />
                                }
                            />
                            <Route
                                path="contacts"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.VIEW}
                                            title={i18n.t('menu.contacts')}
                                        />
                                        <LeuteView
                                            topicModel={topicModel}
                                            leuteModel={leuteModel}
                                            notifications={notifications}
                                            blacklistModel={blacklistModel}
                                            getTrustView={getTrustView}
                                            startGroupChat={startGroupChat.bind(
                                                startGroupChat,
                                                patientModel,
                                                eddaFlexibelChatModel
                                            )}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="connections"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.connections')}
                                        />
                                        <ConnectionsView
                                            connectionsModel={connections}
                                            leuteModel={leuteModel}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="invitePartner"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.person')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <CreateInvitation
                                            connectionsModel={connections}
                                            iomRequestManager={iom.requestManager}
                                            inviteAcceptedRoute="/connections"
                                            mode="iop"
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="inviteDevice"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.device')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <CreateInvitation
                                            connectionsModel={connections}
                                            iomRequestManager={iom.requestManager}
                                            inviteAcceptedRoute="/connections"
                                            mode="iom"
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="invites/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.connections')}
                                        />
                                        <ConnectionsView
                                            connectionsModel={connections}
                                            leuteModel={leuteModel}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="qr-scanner"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.CHEVRON}
                                            title={i18n.t('invitations.qrScanner.title')}
                                            navigateBackOnClose={'/connections'}
                                        />
                                        <QrScanner
                                            connectionsModel={connections}
                                            iomManager={iom}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="identity/*"
                                element={
                                    <IdentityRouter
                                        connectionsModel={connections}
                                        leuteModel={leuteModel}
                                        topicModel={topicModel}
                                        getTrustView={getTrustView}
                                    />
                                }
                            />
                            <Route
                                path="journal/*"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.journal')}
                                        />
                                        <JournalRouter
                                            journalModel={journalModel}
                                            questionnaireModel={questionnaireModel}
                                            leuteModel={leuteModel}
                                            topicModel={topicModel}
                                        />
                                    </>
                                }
                            />
                            <Route
                                path="questionnaire/*"
                                element={
                                    <QuestionnaireRouter questionnaireModel={questionnaireModel} />
                                }
                            />
                            <Route
                                path="patients/*"
                                element={
                                    <PatientsRouter
                                        questionnaireModel={questionnaireModel}
                                        leuteModel={leuteModel}
                                        channelManager={channelManager}
                                        topicModel={topicModel}
                                        notifications={notifications}
                                        journalModel={journalModel}
                                        documentModel={documentModel}
                                        getTrustView={getTrustView}
                                    />
                                }
                            />
                            <Route
                                path="document/*"
                                element={<DocumentRouter documentModel={documentModel} />}
                            />
                            <Route
                                path="diary/*"
                                element={
                                    <DiaryRouter
                                        diaryModel={diaryModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="bodyTemperature/*"
                                element={
                                    <BodyTemperatureRouter
                                        bodyTemperatureModel={bodyTemperatureModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route path="signkeys/*" element={<SignKeysRouter />} />
                            <Route
                                path="wbc/*"
                                element={
                                    <WBCRouter
                                        wbcDiffModel={wbcDiffModel}
                                        channelManager={channelManager}
                                    />
                                }
                            />
                            <Route
                                path="Downloads"
                                element={
                                    <>
                                        <AppBarSettings
                                            mode={APP_BAR_MODE.BROWSE}
                                            title={i18n.t('menu.downloads')}
                                        />
                                        <Downloads />
                                    </>
                                }
                            />
                            <Route path="about/*" element={<AboutRouter />} />
                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                    </>
                )}
            </Routes>
        </BrowserRouter>
    );
}
